export class SenhaValidator {

  static isValid(value) {
    if (value === undefined) {
      return false;
    }

    return value.length >= 6;
  }
}
